import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import Phaser from "phaser";
import loaderScene from "./LoaderScene.js";
import playScene from "./PlayScene.js";

const CiftciOyunu = () => {
  const phaserGame = () => {
    var config = {
      type: Phaser.AUTO,
      antialias: true,
      width: 800,
      height: 600,
      backgroundColor: "#1B75BB",
      parent: "game",
      physics: {
        default: "arcade",
        arcade: { debug: false },
      },
      scale: {
        autoCenter: Phaser.Scale.CENTER_BOTH
      },
      scene: [loaderScene, playScene]
    };

    new Phaser.Game(config);
  };

  useEffect(() => {
    phaserGame();
  });

  return (
    <div>
      <Helmet>
        <title>Sütleri Topla - Çiftlik Oyunu - Oyun Treni</title>
      </Helmet>
      <div id="game"></div>
    </div>
  );
};

export default CiftciOyunu;