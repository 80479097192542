import React from 'react'
import { Helmet } from 'react-helmet-async';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Box from './Box'
import oyunlar from "../../assets/oyunlar.svg"
import dogavehayvanlar from "../../assets/dogavehayvanlar.svg"
import sayilarvesekiller from "../../assets/sayilarvesekiller.svg"
import harflervesozcukler from "../../assets/harflervesozcukler.svg"
import bilmecebulmaca from "../../assets/bilmecebulmaca.svg"
import okuloncesi from "../../assets/okuloncesi.svg"

const Okuloncesi = () => {
    return (
        <>
            <Helmet>
                <title>Oyun Treni - Okul Öncesi Oyunlar, Eğitici Programlar, Uygulamalar</title>
            </Helmet>
            <Header page="okuloncesi" />
            <div className="wrapper">
                <Box class="box red" link="/Oyun" image={oyunlar} text="Oyun Treni" />
                <Box class="box orange" link="/Oyun" image={dogavehayvanlar} text="Doğa ve Hayvanlar" />
                <Box class="box yellow" link="/Oyun" image={sayilarvesekiller} text="Sayılar ve Şekiller" />
                <Box class="box purple" link="/Oyun" image={harflervesozcukler} text="Harfler ve Sözcükler" />
                <Box class="box blue" link="/Oyun" image={bilmecebulmaca} text="Bilmeceler ve Bulmacalar" />
                <Box class="box green" link="/Oyun" image={okuloncesi} text="Okul Öncesi Oyunlar" />
            </div>
            <Footer />
        </>
    )
}

export default Okuloncesi
