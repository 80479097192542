import React, { useEffect } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import firebase from './firebase/firebaseConfig'
import './App.css';
import Pages from './pages'

const App = () => {
  useEffect(() => {
    firebase.analytics();
  }, [])

  return (
    <Router>
      <div className="page">
        <Pages />
      </div>
    </Router>
  )
}

export default App
