import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <div className="text">
                <span><a href="https://oyuntreni.com">OyunTreni.com</a> Okul öncesi eğitim oyunları. Reklamsız ve güvenli oyunlar. Çocuklar için eğitici oyunlar
                ve
                uygulamalar. Çocuklar, rakamları, şekilleri, renkleri öğrenirken eğlencekler. Meyvelerle, taşıtlarla, eşyalarla
                en sevdikleri eşleştirme oyunlarını oynayacaklar. <a href="https://oyuntreni.com">Oyun Treni</a> üzerindeki oyunlar, yüksek kalite görselller ve sesler kullanılarak <a href="https://oyuntreni.com">Oyun Treni</a>'ne
                özel olarak, uzmanlar denetiminde hazırlanmıştır. <a href="https://oyuntreni.com">OyunTreni.com</a> 2021 Her hakkı saklıdır.</span>
            </div>
        </div>
    )
}

export default Footer
