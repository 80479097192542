import React from 'react'
import { Switch, Route } from "react-router-dom";
import Dashboard from './Dashboard'
import Okuloncesi from './Okuloncesi'
import Egiticioyunlar from './Egiticioyunlar'
import Bulmacalar from './Bulmacalar'
import Games from '../games'

const Pages = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route path="/Okul-Oncesi" component={Okuloncesi} />
                <Route path="/Egitici-Oyunlar" component={Egiticioyunlar} />
                <Route path="/Bulmacalar" component={Bulmacalar} />
                <Route path="/Matematik-Oyunları" component={Dashboard} />
                <Route path="/Alfabe-Oyunları" component={Dashboard} />
                <Route exact path="/Oyun" component={Games} />
                <Route exact path="/Oyun/:name-:key" component={Games} />
                <Route exact path='*' component={Dashboard} />
            </Switch>
        </div>
    )
}

export default Pages
