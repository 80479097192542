import Phaser from "phaser";

import Saat from "./Saat.svg";
import Akrep from "./Akrep.svg";
import Yelkovan from "./Yelkovan.svg";
import Nokta from "./Nokta.svg";
import Makas from "./Makas.svg";
import KesikCizgi from "./KesikCizgi.svg";
import Zemin from "./Zemin.svg";
import cursor from "./cursor.svg";
import cursordrag from "./cursordrag.svg";

import Yuvarlak from "./Yuvarlak.svg";
import Yildiz from "./Yildiz.svg";
import Kare from "./Kare.svg";
import Altigen from "./Altigen.svg";
import Dilim from "./Dilim.svg";
import Sekizgen from "./Sekizgen.svg";
import Kalp from "./Kalp.svg";
import Ucgen from "./Ucgen.svg";
import Karo from "./Karo.svg";
import Besgen from "./Besgen.svg";
import Oval from "./Oval.svg";
import Yamuk from "./Yamuk.svg";

import rakam0 from "./01.svg";
import rakam1 from "./02.svg";
import rakam2 from "./03.svg";
import rakam3 from "./04.svg";
import rakam4 from "./05.svg";
import rakam5 from "./06.svg";
import rakam6 from "./07.svg";
import rakam7 from "./08.svg";
import rakam8 from "./09.svg";
import rakam9 from "./10.svg";
import rakam10 from "./11.svg";
import rakam11 from "./12.svg";

import win from "./win.wav";
import truew from "./true.wav";

export default class playScene extends Phaser.Scene {

  constructor() {
      super({
          key: "playScene"
      });
  }

  preload() {
      this.load.svg("Saat", Saat);
      this.load.svg("Akrep", Akrep);
      this.load.svg("Yelkovan", Yelkovan);
      this.load.svg("Nokta", Nokta);

      this.load.svg("Makas", Makas);
      this.load.svg("KesikCizgi", KesikCizgi);
      this.load.svg("Zemin", Zemin);

      this.load.svg("Yuvarlak", Yuvarlak);
      this.load.svg("Yildiz", Yildiz);
      this.load.svg("Kare", Kare);
      this.load.svg("Altigen", Altigen);
      this.load.svg("Dilim", Dilim);
      this.load.svg("Sekizgen", Sekizgen);
      this.load.svg("Kalp", Kalp);
      this.load.svg("Ucgen", Ucgen);
      this.load.svg("Karo", Karo);
      this.load.svg("Besgen", Besgen);
      this.load.svg("Oval", Oval);
      this.load.svg("Yamuk", Yamuk);

      this.load.svg("0", rakam0);
      this.load.svg("1", rakam1);
      this.load.svg("2", rakam2);
      this.load.svg("3", rakam3);
      this.load.svg("4", rakam4);
      this.load.svg("5", rakam5);
      this.load.svg("6", rakam6);
      this.load.svg("7", rakam7);
      this.load.svg("8", rakam8);
      this.load.svg("9", rakam9);
      this.load.svg("10", rakam10);
      this.load.svg("11", rakam11);

      this.load.audio("win", win);
      this.load.audio("truew", truew);
  }

  create() {
      this.skor = 0;

      this.sound.add('win');
      this.sound.add('truew');

      this.input.setDefaultCursor('url(' + cursor + '), pointer');

      this.Saat = this.physics.add.sprite(400, 300, "Saat");

      this.sekiller = this.add.group();

      this.Yildiz = this.physics.add.sprite(480, 185, "Yildiz");
      this.Kare = this.physics.add.sprite(530, 245, "Kare");
      this.Altigen = this.physics.add.sprite(540, 300, "Altigen");
      this.Dilim = this.physics.add.sprite(520, 360, "Dilim");
      this.Sekizgen = this.physics.add.sprite(480, 420, "Sekizgen");
      this.Kalp = this.physics.add.sprite(400, 440, "Kalp");
      this.Ucgen = this.physics.add.sprite(330, 420, "Ucgen");
      this.Karo = this.physics.add.sprite(280, 370, "Karo");
      this.Besgen = this.physics.add.sprite(260, 300, "Besgen");
      this.Oval = this.physics.add.sprite(270, 240, "Oval");
      this.Yamuk = this.physics.add.sprite(320, 180, "Yamuk");
      this.Yuvarlak = this.physics.add.sprite(400, 160, "Yuvarlak");

      this.sekiller.add(this.Yildiz);
      this.sekiller.add(this.Kare);
      this.sekiller.add(this.Altigen);
      this.sekiller.add(this.Dilim);
      this.sekiller.add(this.Sekizgen);
      this.sekiller.add(this.Kalp);
      this.sekiller.add(this.Ucgen);
      this.sekiller.add(this.Karo);
      this.sekiller.add(this.Besgen);
      this.sekiller.add(this.Oval);
      this.sekiller.add(this.Yamuk);
      this.sekiller.add(this.Yuvarlak);

      this.Yildiz.surname = 0;
      this.Kare.surname = 1;
      this.Altigen.surname = 2;
      this.Dilim.surname = 3;
      this.Sekizgen.surname = 4;
      this.Kalp.surname = 5;
      this.Ucgen.surname = 6;
      this.Karo.surname = 7;
      this.Besgen.surname = 8;
      this.Oval.surname = 9;
      this.Yamuk.surname = 10;
      this.Yuvarlak.surname = 11;

      this.Akrep = this.physics.add.sprite(400, 300, "Akrep");
      this.Akrep.setOrigin(0.5, 1);
      this.Akrep.angle = 310;

      this.Yelkovan = this.physics.add.sprite(400, 300, "Yelkovan");
      this.Yelkovan.setOrigin(0.5, 1);
      this.Yelkovan.angle = 50;

      this.Nokta = this.physics.add.sprite(400, 300, "Nokta");

      this.Zemin = this.physics.add.sprite(400, 560, "Zemin");
      this.KesikCizgi = this.physics.add.sprite(400, 520, "KesikCizgi");
      this.KesikCizgi.angle = 90;
      this.Makas = this.physics.add.sprite(50, 520, "Makas");
      this.Makas.angle = 90;

      const yerlesim = [[100, 50], [100, 135], [100, 220], [100, 305], [100, 390], [100, 475], [700, 50], [700, 135], [700, 220], [700, 305], [700, 390], [700, 475]]
      this.shuffle(yerlesim)

      this.rakam = [];

      this.sayilar = this.add.group();

      for (let index = 0; index < 12; index++) {
          this.rakam[index] = this.physics.add.sprite(yerlesim[index][0], yerlesim[index][1], index);
          this.rakam[index].setCollideWorldBounds(true);
          this.rakam[index].setInteractive({ draggable: true, cursor: 'url(' + cursordrag + '), pointer' });
          this.rakam[index].ilkX = yerlesim[index][0];
          this.rakam[index].ilkY = yerlesim[index][1];
          this.rakam[index].surname = index;
          this.startUp(this.rakam[index]);
          this.sayilar.add(this.rakam[index]);
      }


      this.title = this.add.text(310, 15, 'Saat Tamiri', {
          fontFamily: 'Nerko One',
          color: '#764C29',
          fontSize: '4em'
      });

      this.desc = this.add.text(180, 60, 'Saatimizin çalışması için rakamları yerlerine dizmeliyiz!', {
          fontFamily: 'Nerko One',
          color: '#764C29',
          fontSize: '2em'
      });

      this.ipucu = this.add.text(180, 540, 'İpucu : Rakamların renkleri ve şekilleri birbirinden farklı.', {
          fontFamily: 'Nerko One',
          color: '#764C29',
          fontSize: '2em'
      });

      this.input.on("gameobjectover", function (pointer, gameObject) {
      });

      this.input.on("gameobjectout", function (pointer, gameObject) {
      });

      this.input.on("drag", function (pointer, gameObject, dragX, dragY) {
          gameObject.x = dragX;
          gameObject.y = dragY;
      });

      this.input.on("dragend", function (pointer, gameObject, dragX, dragY) {
          gameObject.x = gameObject.ilkX;
          gameObject.y = gameObject.ilkY;
      });

      this.physics.add.overlap(this.sayilar, this.sekiller, this.carpisma.bind(this));
  }

  carpisma(sayi, sekil) {
      this.sayi = sayi;
      this.sekil = sekil;

      if (sayi.surname === sekil.surname) {
          this.sayi.destroy();
          this.sekil.setTexture(sayi.surname)
          this.startUp(this.sekil);
          this.skor++;
          this.sound.play("truew");
          if (this.skor > 11) {
              this.sound.play("win");
          }
      }
  }

  startUp(object) {
      this.tweens.add({
          targets: object,
          scaleX: "+=.1",
          scaleY: "+=.1",
          rotation: 10,
          duration: 300,
          ease: "Cubic",
          repeat: 0,
          yoyo: true,
      });
  }

  shuffle(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;

      while (0 !== currentIndex) {

          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
      }

      return array;
  }

  basarim() {
      this.Yelkovan.angle++
      this.Akrep.angle = this.Akrep.angle + 0.5
  }

  update() {
      if (this.skor > 11) {
          this.basarim()
      }
  }
}