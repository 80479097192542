import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
import CiftciOyunu from './CiftciOyunu'
import CivcivOyunu from './CivcivOyunu'
import InekOyunu from './InekOyunu'
import SaatOyunu from './SaatOyunu'


const Games = () => {
    let { key } = useParams();
    const [random] = useState(Math.floor(Math.random() * Math.floor(5)));

    return (
        <>
            <Header page="dashboard" />
            <div className="wrapper">
                <div className="left"></div>
                <Game game={!key ? random.toString() : key} />
                <div className="right"></div>
            </div>
            <Footer />
        </>
    )
}

const Game = (props) => {
    switch (props.game) {
        case '1':
            return <CiftciOyunu />
        case '2':
            return <CivcivOyunu />
        case '3':
            return <InekOyunu />
        case '4':
            return <SaatOyunu />
        default:
            return <CivcivOyunu />
    }
}

export default Games
