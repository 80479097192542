import React from 'react'
import { Link } from "react-router-dom";

const Box = (props) => {
    return (
        <Link className={props.class} to={props.link}>
            <h1>{props.text}</h1>
            <h2>Tüm oyunlar burada...</h2>
            <img src={props.image} alt={props.text} width="256" height="256" />
        </Link>
    )
}

export default Box
