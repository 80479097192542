import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import OyunTreni from "../assets/oyuntreni.svg"

const Header = (props) => {

    const [dashboard, setDashboard] = useState(false)
    const [okuloncesi, setOkuloncesi] = useState(false)
    const [egiticioyunlar, setEgiticioyunlar] = useState(false)
    const [bulmacalar, setBulmacalar] = useState(false)

    useEffect(() => {
        switch (props.page) {
            case "dashboard":
                setDashboard(true)
                break;
            case "okuloncesi":
                setOkuloncesi(true)
                break;
            case "egiticioyunlar":
                setEgiticioyunlar(true)
                break;
            case "bulmacalar":
                setBulmacalar(true)
                break;
            default:
                setDashboard(false)
                setOkuloncesi(false)
                break;
        }
    }, [props.page])


    return (
        <div className="header">
            <ul id="nav">
                <li><img src={OyunTreni} width="150" height="150" alt="Oyun Treni" /></li>
                <li><Link to="/" className={(dashboard ? 'active' : '')}>Oyun Treni</Link></li>
                <li><Link to="/Okul-Oncesi" className={(okuloncesi ? 'active' : '')}>Okul Öncesi Oyunlar</Link></li>
                <li><Link to="/Egitici-Oyunlar" className={(egiticioyunlar ? 'active' : '')}>Eğitici Oyunlar</Link></li>
                <li><Link to="/Bulmacalar" className={(bulmacalar ? 'active' : '')}>Bulmacalar</Link></li>
            </ul>
        </div>
    )
}

export default Header

