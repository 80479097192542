import Phaser from "phaser";
import zeminoyun from "./zeminoyun.svg";
import zeminpuan from "./zeminpuan.svg";
import ciftci from "./ciftci.svg";
import object1 from "./object1.svg";
import object2 from "./object2.svg";
import object3 from "./object3.svg";
import cursor from "./cursor.svg";
import cursordrag from "./cursordrag.svg";

export default class playScene extends Phaser.Scene {
  constructor() {
    super({
      key: "playScene"
    });
    this.places = [[150, 150], [230, 280], [350, 250], [450, 250], [150, 400], [450, 120], [550, 180], [640, 230], [650, 400], [630, 120]];
    this.dokunan = null;
  }

  preload() {
    this.load.svg("zeminoyun", zeminoyun);
    this.load.svg("zeminpuan", zeminpuan);
    this.load.svg("ciftci", ciftci);
    this.load.svg("object1", object1);
    this.load.svg("object2", object2);
    this.load.svg("object3", object3);
  }

  create() {
    this.input.setDefaultCursor('url(' + cursor + '), pointer');

    this.zeminoyun = this.physics.add.sprite(400, 250, "zeminoyun");

    this.puanzeminleri = this.add.group();
    this.puanzemin1 = this.physics.add.sprite(100, 550, "zeminpuan");
    this.puanzemin1.nesneTipi = 'object1';
    this.puanzeminleri.add(this.puanzemin1);
    this.puanzemin2 = this.physics.add.sprite(240, 550, "zeminpuan");
    this.puanzemin2.nesneTipi = 'object2';
    this.puanzeminleri.add(this.puanzemin2);
    this.puanzemin3 = this.physics.add.sprite(600, 550, "zeminpuan");
    this.puanzemin3.nesneTipi = 'object3';
    this.puanzeminleri.add(this.puanzemin3);

    this.ciftci = this.physics.add.sprite(400, 470, "ciftci");

    this.object1ico = this.physics.add.sprite(70, 550, "object1");
    this.object1ico.setScale(0.5);

    this.object2ico = this.physics.add.sprite(210, 550, "object2");
    this.object2ico.setScale(0.5);

    this.object3ico = this.physics.add.sprite(570, 550, "object3");
    this.object3ico.setScale(0.5);

    this.gecensure = 0;
    this.skor1 = 0;
    this.skor2 = 0;
    this.skor3 = 0;
    this.toplamskor = 0;

    this.gecensureText = this.add.text(690, 10, this.gecensure + ' sn.', {
      fontFamily: 'Nerko One',
      color: '#D6DE23',
      fontSize: '4em'
    });

    this.skorText1 = this.add.text(110, 530, this.skor1, {
      fontFamily: 'Nerko One',
      color: '#764C29',
      fontSize: '4em'
    });

    this.skorText2 = this.add.text(240, 530, this.skor2, {
      fontFamily: 'Nerko One',
      color: '#764C29',
      fontSize: '4em'
    });

    this.skorText3 = this.add.text(610, 530, this.skor3, {
      fontFamily: 'Nerko One',
      color: '#764C29',
      fontSize: '4em'
    });

    this.objects = this.add.group();

    for (let index = 0; index < this.places.length; index++) {
      var value = Phaser.Math.Between(1, 3);
      let objectType;

      switch (value) {
        case 2:
          objectType = 'object2';
          break;
        case 3:
          objectType = 'object3';
          break;
        default:
          objectType = 'object1';
          break;
      }

      this.object = this.physics.add.sprite(this.places[index][0], this.places[index][1], objectType);
      this.object.setName(objectType + index);
      this.object.nesneTipi = objectType;
      this.object.ilkX = this.places[index][0];
      this.object.ilkY = this.places[index][1];
      this.object.setCollideWorldBounds(true);
      this.startUp(this.object);
      this.object.setInteractive({ draggable: true, cursor: 'url(' + cursordrag + '), pointer' });
      this.objects.add(this.object);
    }

    this.add.text(10, 10, 'Civcivleri Topla', {
      fontFamily: 'Nerko One',
      color: '#D6DE23',
      fontSize: '3.5em'
    });


    this.add.text(10, 50, this.places.length + ' civcivi ' + this.places.length * 1.5 + ' saniyede toplayabilir misin?', {
      fontFamily: 'Nerko One',
      color: '#F26751',
      fontSize: '2.5em'
    });

    this.input.on("gameobjectover", function (pointer, gameObject) {
    });

    this.input.on("gameobjectout", function (pointer, gameObject) {
    });

    this.input.on("drag", function (pointer, gameObject, dragX, dragY) {
      gameObject.x = dragX;
      gameObject.y = dragY;
    });

    this.input.on("dragend", function (pointer, gameObject, dragX, dragY) {
      gameObject.x = gameObject.ilkX;
      gameObject.y = gameObject.ilkY;
    });

    this.physics.add.overlap(this.objects, this.puanzeminleri, this.carpisma.bind(this));

    this.timedEvent = this.time.addEvent({ delay: 1000, callback: this.sureGecir, callbackScope: this, loop: true });

    this.restartText = this.add.text(300, 250, 'Yeniden Oyna', {
      fontFamily: 'Nerko One',
      color: '#1C79BE',
      fontSize: '3.5em'
    });

    this.restartText.setInteractive({ cursor: 'url(' + cursordrag + '), pointer' });
    this.restartText.on('pointerdown', function () {
      this.scene.restart();
    }, this)
    this.restartText.visible = false;

    this.startText = this.add.text(300, 250, 'Yeniden Dene', {
      fontFamily: 'Nerko One',
      color: '#1C79BE',
      fontSize: '3.5em'
    });

    this.startText.setInteractive({ cursor: 'url(' + cursordrag + '), pointer' });
    this.startText.on('pointerdown', function () {
      this.scene.restart();
    }, this)
    this.startText.visible = false;

  }

  startUp(object) {
    this.tweens.add({
      targets: object,
      scaleX: "+=.1",
      scaleY: "+=.1",
      rotation: 10,
      duration: 300,
      ease: "Cubic",
      repeat: 0,
      yoyo: true,
    });
  }

  sureGecir() {
    this.gecensure += 1;
    this.gecensureText.setText(this.gecensure + ' sn.');
  }

  carpisma(sprite, zemin) {

    if (sprite.nesneTipi === zemin.nesneTipi) {
      this.dokunan = sprite;
      switch (sprite.nesneTipi) {
        case 'object2':
          this.skor2 += 1;
          this.toplamskor += 1;
          this.skorText2.setText(this.skor2);
          this.startUp(this.object2ico);
          this.dokunan.destroy();
          break;
        case 'object3':
          this.skor3 += 1;
          this.toplamskor += 1;
          this.skorText3.setText(this.skor3);
          this.startUp(this.object3ico);
          this.dokunan.destroy();
          break;
        default:
          this.skor1 += 1;
          this.toplamskor += 1;
          this.skorText1.setText(this.skor1);
          this.startUp(this.object1ico);
          this.dokunan.destroy();
          break;
      }

      if (this.toplamskor >= this.places.length) {
        this.timedEvent.remove();
        this.add.text(200, 200, this.places.length + ' civcivi ' + this.gecensure + ' saniyede topladın.', {
          fontFamily: 'Nerko One',
          color: '#F26751',
          fontSize: '3.5em'
        });

        this.restartText.visible = true;
      }

    }

  }

  update() {
    if (this.gecensure >= this.places.length * 1.5) {
      this.objects.clear(true,true);
      //this.objects.destroy({ destroyChildren: true });
      this.timedEvent.remove();
      this.add.text(200, 200, this.places.length + ' civcivi ' + this.gecensure + ' saniyede toplayamadın!', {
        fontFamily: 'Nerko One',
        color: '#F26751',
        fontSize: '3.5em'
      });
      this.startText.visible = true;
    }
  }

}