import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyCRT_A4WmH0qyl23GapZjd2q-dUyUn93jA",
    authDomain: "oyuntreni.firebaseapp.com",
    databaseURL: "https://oyuntreni.firebaseio.com",
    projectId: "oyuntreni",
    storageBucket: "oyuntreni.appspot.com",
    messagingSenderId: "778137643887",
    appId: "1:778137643887:web:5be6309c1c4198440b569a",
    measurementId: "G-79WJDK42E9"
};

firebase.initializeApp(firebaseConfig);
firebase.auth();
firebase.firestore();
firebase.analytics();

export default firebase