import Phaser from "phaser";

export default class loaderScene extends Phaser.Scene {
    constructor() {
        super({
            key: "loaderScene"
        });
    }

    preload() {
    }

    create() {
        this.gecensure = 0;

        this.add.text(300, 250, 'Sütleri Topla', {
            fontFamily: 'Nerko One',
            color: '#D6DE23',
            fontSize: '3.5em'
        });

        this.add.text(170, 300, 'Sütleri toplayarak ineklere yardımcı olalım...', {
            fontFamily: 'Nerko One',
            color: '#F26751',
            fontSize: '2.5em'
        });

        this.loaderZemin = this.add.rectangle(400, 370, 400, 30, 0xF26751);
        this.loaderUst = this.add.rectangle(400, 370, 385, 20, 0xFFFFFF);
        this.loaderUst.width = 0;
        

        this.timedEvent = this.time.addEvent({ delay: 1000, callback: this.sureGecir, callbackScope: this, loop: true });
        this.timedLoader = this.time.addEvent({ delay: 25, callback: this.sureLoader, callbackScope: this, loop: true });
    }

    sureLoader() {
        this.loaderUst.width += (385/200);
    }

    sureGecir() {
        this.gecensure += 1;
    }

    update() {
        
        if (this.gecensure >= 5) {
            this.timedEvent.remove();
            this.timedLoader.remove();
            this.scene.start('playScene')
        }
    }
}

